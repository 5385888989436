import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import React, { useEffect, useState, RefObject } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';

const PickerStyles = styled.div`
    .dropdown-menu {
        height: 10rem;
        overflow-y: scroll;
    }
`;

interface Props {
    countryCode: string;
    onSelectionUpdate: Function;
    countries: Array<any>
}

function CountryCodePicker({ 
    countryCode, 
    onSelectionUpdate,
    countries
 }: Props) {
    let [selectedItem, setSelectedItem] = useState(countryCode);
    let [searchString, setSearchString] = useState("");
    let [countryOptionRefs, setCountryOptionRefs] = useState<{ [key: string]: RefObject<any>; }>({});
    useEffect(() => {
        setSelectedItem(countryCode)
    }, [countryCode, countryOptionRefs])
    function getOptions() {
        let options : Array<any> = [];
        countries.forEach(country => {
            let ref : RefObject<any> =  countryOptionRefs[country.alpha_2];
            if (!ref) {
                ref = React.createRef();
                countryOptionRefs[country.alpha_2] = ref;
                setCountryOptionRefs({
                    ...countryOptionRefs
                });
            }
            options.push(
                <Dropdown.Item
                    eventKey={country.alpha_2}
                    id={country.alpha_2}
                    key={country.alpha_2}
                    ref={ref}
                >
                    {
                    `
                    ${getUnicodeFlagIcon(country.alpha_2)} 
                    ${country.name}
                    +${country.code}
                    `
                    }
                </Dropdown.Item>
            )
        });
        return options;
    }
    function getTitle() {
        let country = countries.find(c => c.alpha_2 === selectedItem);
        return `${getUnicodeFlagIcon(country.alpha_2)} +${country.code}`
    }
    function handleSelectEvent(key: string | null, e: React.SyntheticEvent<unknown>) {
        let { id } = e.target as any;
        setSelectedItem(id);
        let country = countries.find(c => c.alpha_2 === id);
        onSelectionUpdate(country);
    }
    function searchCountry(e: React.KeyboardEvent) {
        setSearchString(searchString + String.fromCharCode(e.charCode));
        let methods = ["startsWith", "includes"];
        for (let method of methods) {
            let foundCountry = countries.find(c => c.name.toLowerCase()[method](searchString.toLowerCase()));
            if (foundCountry) {
                countryOptionRefs[foundCountry.alpha_2].current.scrollIntoView();
                break;
            }
        }
        setTimeout(() => {
            setSearchString("")
        }, 2000);
    }
    return (
        <PickerStyles>
            <DropdownButton
                as={ButtonGroup}
                key="country-code-picker"
                id="country-code-picker"
                variant="country-code-picker"
                title={getTitle()}
                onSelect={handleSelectEvent}
                onKeyPress={searchCountry}
            >
                {getOptions()}
            </DropdownButton>
        </PickerStyles>
    );
}

export default CountryCodePicker;