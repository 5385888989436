import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Firebase from "../../provider/firebase";

export default function ForgotPassword(props: any) {
    const [email, setEmail] = useState<string>();
    let [errorCode, setErrorCode] = useState<string>();
    const navigate = useNavigate();

    async function sendPasswordResetEmail() {
        if (!email) {
            setErrorCode("Invalid email");
            return;
        }
        try {
            await Firebase.sendPasswordResetEmail(Firebase.auth, email);
            navigate("/reset-email-sent");
        } catch (err: any) {
            console.error(err, "Failed sendPasswordResetEmail");
            let errorCode = err?.code || "Unknown error";
            setErrorCode(errorCode);
        }
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setEmail(value);
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div className="auth-main">
                    <div>
                        <h3>Forgot password</h3>
                        <div className="mb-3">
                            <label>Email address</label>
                            <input
                                defaultValue={email}
                                type="email"
                                className="form-control"
                                placeholder="Enter email"
                                id="email"
                                onChange={onInputChange}
                                autoComplete="none"
                            />
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-primary" onClick={sendPasswordResetEmail}>
                                Submit
                            </button>
                        </div>
                        {errorCode &&
                            <div>
                                <div>Error: {errorCode}</div>
                            </div>
                        }
                        <p className="dialogue-footer">
                            <span className='text-right'>
                            Back to <a href="../sign-in">login</a>?
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

}