import React from "react";

const Unauthorized = () => {
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div>You donot have permission to view this page</div>
      </div>
    </div>
  );
};

export default Unauthorized;
