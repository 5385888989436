import React, { useState } from 'react'
import Firebase from "../../../provider/firebase";
import { AuthMethod, createUser } from '../../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../../provider/AuthProvider';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface EmailSignupInputs {
    email: string | null;
    password: string | null;
    first_name: string | null;
    last_name: string | null;
}

export default function EmailSignup(props: any) {
    const { login } = useAuth() as AuthContextType;
    const [errorCode, setErrorCode] = useState("");
    const navigate = useNavigate();
    const state : EmailSignupInputs = {
        email: null,
        password: null,
        first_name: null,
        last_name: null
    };

    async function singup() {
        try {
            let userCredential = await Firebase.createUserWithEmailAndPassword(Firebase.auth, state.email as string, state.password as string);
            await Firebase.updateProfile(userCredential.user, {
                displayName: `${state.first_name} ${state.last_name}`
            });
            let firebaseToken = await userCredential.user.getIdToken();
            let dbUser = await createUser(firebaseToken, AuthMethod.email);
            if (!userCredential.user.emailVerified) {
                sendEmailVerification(userCredential.user);
                navigate({
                    pathname: '/auth-email',
                    search: '?mode=verifyEmail&_action=waitForEmail',
                });
            } else {
                login({ firebaseUser: Firebase.auth.currentUser, dbUser });
            }
        } catch (error: any) {
            console.error(error);
            setErrorCode(error?.code);
        }
    }


    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { id, value } = e.target;
        switch (id) {
            case "email": {
                state.email = value;
                break;
            }
            case "password": {
                state.password = value;
                break;
            }
            case "first_name": {
                state.first_name = value;
                break;
            }
            case "last_name": {
                state.last_name = value;
                break;
            }
        }
    }

    return (
        <div>
            <div className="mb-3">
                <label>First name</label>
                <input
                    defaultValue={state.first_name as string}
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    id="first_name"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>

            <div className="mb-3">
                <label>Last name</label>
                <input
                    defaultValue={state.last_name as string}
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    id="last_name"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>

            <div className="mb-3">
                <label>Email address</label>
                <input
                    defaultValue={state.email as string}
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    id="email"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>

            <div className="mb-3">
                <label>Password</label>
                <input
                    defaultValue={state.password as string}
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    id="password"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>
            <div>{errorCode || ""}</div>

            <div className="d-grid">
                <button className="btn btn-primary" onClick={singup}>
                    Sign Up
                </button>
            </div>
            <p className="dialogue-footer">
                <span className='text-right'>
                    Already registered <a href="/sign-in">sign in?</a>
                </span>
            </p>
        </div>
    )

}
