const apiEnv : string | undefined = process.env.REACT_APP_FLIGHT_BUILD_NODE_ENV || process.env.REACT_APP_FLIGHT_NODE_ENV;

interface Config {
    api: APIConfig
    firebase: FirebaseConfig
    env: string
}

interface APIConfig {
    endpoint: String
}

interface FirebaseConfig {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
}

const envConfig : { [key: string]: Config; }= {
    local: {
        api: {
            endpoint: "http://localhost:3002/"
        },
        firebase: {
            apiKey: "AIzaSyBKb-Bt7UT5jJGnXgQowoMR74KjoQWtn0M",
            authDomain: "dev-flight-trypp-in.firebaseapp.com",
            projectId: "dev-flight-trypp-in",
            storageBucket: "dev-flight-trypp-in.appspot.com",
            messagingSenderId: "344764327565",
            appId: "1:344764327565:web:aadf9f1e26eb7de1f364c4",
            measurementId: "G-5TGZMQGKNP"
        },
        env: "local"
    },
    dev: {
        api: {
            endpoint: "https://dev.trypp.in/v1/"
        },
        firebase: {
            apiKey: "AIzaSyBKb-Bt7UT5jJGnXgQowoMR74KjoQWtn0M",
            authDomain: "dev-flight-trypp-in.firebaseapp.com",
            projectId: "dev-flight-trypp-in",
            storageBucket: "dev-flight-trypp-in.appspot.com",
            messagingSenderId: "344764327565",
            appId: "1:344764327565:web:aadf9f1e26eb7de1f364c4",
            measurementId: "G-5TGZMQGKNP"
        },
        env: "dev"
    },
    prod: {
        api: {
            endpoint: "https://api.trypp.in/v1/"
        },
        firebase: {
            apiKey: "AIzaSyAdtaGiSAANnrANd3joKwqGfdKtCc9YX9s",
            authDomain: "flight-trypp-in.firebaseapp.com",
            projectId: "flight-trypp-in",
            storageBucket: "flight-trypp-in.appspot.com",
            messagingSenderId: "755893764546",
            appId: "1:755893764546:web:ee6c0adf9d67ddee8b965b",
            measurementId: "G-VDNBQ1KHNN"
        },
        env: "prod"
    }
}

const config : Config = envConfig[apiEnv as string];
export default config;