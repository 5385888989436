import React, { useEffect, useState } from 'react'
import AirportSelector from './airports.component';
import CountrySelector from './countries.component';
import styled from 'styled-components'
import DateTimePicker from '../common/date-time-picker';
import { getLocation, getQueueWaitTime, getTerminalAlias } from '../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../provider/AuthProvider';
import { getDateParts } from '../../utils/date-time-functions';
import ComboBoxComponent from '../common/combo-box.component';

const MainDiv = styled.div`
    min-height:20rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .queue-time {
        padding: 5rem;
    }
    .queue-time-mins {
        font-size:2rem;
    }
    @media(max-width: 600px) {
        .queue-time {
            padding: 1rem;
        }
    }
`;

const ParentDiv = styled.div`
  & {
    width: 100%;
  }
  @media(max-width: 600px) {
    min-height:12rem;
    display: grid;
  }
  @media(max-width: 400px) {
    min-height:8rem;
    display: grid;
  }
`;

const ChildDiv = styled.div`
  & {
    display: inline-block;
    vertical-align: text-top;
    margin: 0 auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
`;


export default function QueueChecker(props: any) {
    let [country, setCountry] = useState<string>();
    let [airport, setAirport] = useState<string|null>();
    let [timeSelected, setTimeSelected] = useState(new Date());
    let [timeRequested, setTimeRequested] = useState<Date|null>(null);
    let [queueTime, setQueueTime] = useState('unknown');
    let [terminals, setTerminals] = useState<any[]>([]);
    let [terminal, setTerminal] = useState<string|null>(null);
    let { user } = useAuth() as AuthContextType;
    useEffect(() => {
        setTimeRequested(null);
        async function loadDefaultCountry() {
            if (!country) {
                let defaultCountry = await getLocation();
                setCountry(defaultCountry.alpha_2);
            }
        }
        loadDefaultCountry();
    },[airport,country])
    useEffect(() => {
        setAirport(null)
    },[country])
    function updateCountry(country: string) {
        console.log(country)
        setCountry(country);
    }
    function updateAirport(airport: string) {
        setAirport(airport);
        loadTerminals(airport);
    }
    async function loadTerminals(airport: string) {
        let terminalNames  = await getTerminalAlias(user.user.dbUser.appToken, airport);
        terminals = terminalNames.map((t: string) => {
            return {id: t, value: t};
        });
        setTerminals(terminals);
    }
    function getStartDate() {
        return new Date();
    }
    function getEndDate() {
        let now = new Date();
        now.setDate(now.getDate() + 5);
        return now;
    }
    function onNewTimeSelected(newTime: Date) {
        if (!airport || !country) {
            return;
        }
        setTimeSelected(newTime);
        let selectedTerminal = terminal === "-" ? null : terminal;
        getQueueWaitTime(user.user.dbUser.appToken, airport, newTime.getTime(), selectedTerminal)
            .then((data: any) => {
                let { mins, requestedTime } = data;
                setQueueTime(mins);
                setTimeRequested(new Date(+(requestedTime as number)));
            }).catch((err)=>{
                console.error("Failed getting queue time", err, timeRequested, timeSelected);
            });
    }
    function getDateDisplay() {
        if (!timeRequested) {
            return;
        }
        let dateParts = getDateParts(timeRequested);
        return (<div>
            <span>{dateParts.date}{dateParts.dateSuffix} </span>
            <span>{dateParts.monthName}, </span>
            <span>{dateParts.year} </span>
            <span>{dateParts.hourStr}:</span>
            <span>{dateParts.minStr} </span>
            <span>{dateParts.ampm}</span>
        </div>);
    }
    return (

        <div className="page-wrapper">
            <div className="page-inner">
                <div>
                    <h3>QueueChecker</h3>
                    <ParentDiv>
                        <ChildDiv>
                            <CountrySelector
                                defaultCountry={country as string}
                                onSelectionUpdate={updateCountry}
                            ></CountrySelector>
                        </ChildDiv>
                        <ChildDiv>
                            <AirportSelector
                                country={country as string}
                                onSelectionUpdate={updateAirport}
                            ></AirportSelector>
                        </ChildDiv>
                        <ChildDiv>
                            <ComboBoxComponent
                                data={terminals}
                                onSelectionUpdate={setTerminal}
                            />
                        </ChildDiv>
                        <ChildDiv>
                            <DateTimePicker
                                startTime={getStartDate()}
                                endTime={getEndDate()}
                                onPick={onNewTimeSelected}
                                maxDates={10}
                                minInterval={5}
                            />
                        </ChildDiv>
                    </ParentDiv>
                    <MainDiv>

                        {timeRequested? 
                            <h5 className='queue-time'>
                                <div>Your estimated queue time for </div>
                                <div>{airport} airport, {country}</div>
                                <div> at {getDateDisplay()} is </div>
                                <div><span className='queue-time-mins'>{queueTime}</span><span> minutes</span></div>
                            </h5>
                            :
                            <h5 className='queue-time'>Select a time to check estimated queue time</h5>
                        }
                        
                    </MainDiv>
                </div>
            </div>
        </div>
    )

}
