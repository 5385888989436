import React, { useState } from 'react'
import { AuthMethod, createUser } from '../../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../../provider/AuthProvider';
import Firebase from "../../../provider/firebase";
import styled from 'styled-components';
import { UserCredential } from 'firebase/auth';

const GoogleStyles = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    width: 100%;
    text-align: center;
    .google-signin {
        width: 100%;
    }
    .google-signin-heading {
        padding-bottom:1rem;
    }
    #auth-methods-tab {
        width: 100%
    }
`;
export default function GoogleLogin() {
    let [authError, setAuthError] = useState("");
    const { login } = useAuth() as AuthContextType;
    async function signIn() {//
        try {
            let result : UserCredential = await Firebase.signInWithPopup(Firebase.auth, Firebase.googleAuthProvider);
            const token = await result.user.getIdToken();
            let dbUser = await createUser(token, AuthMethod.google);
            login({ firebaseUser: Firebase.auth.currentUser, dbUser });
        } catch (error: any) {
            console.error(error);
            setAuthError(error.code);
        }
    }
    return (
        <GoogleStyles>
            <div className='google-signin'>
                <div className="amb-3 google-signin-heading">
                    <label>Sign in with google</label>
                </div>
                <div className="d-grid">
                    <div>{authError || ""}</div>
                    <button className="btn btn-primary" onClick={signIn}>
                        Sign in
                    </button>
                </div>
            </div>
        </GoogleStyles>
    );
}