import { sendEmailVerification } from 'firebase/auth';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../../provider/AuthProvider';
import Firebase from "../../../provider/firebase";

interface EmailLoginInputs {
    email: string | null;
    password: string | null;
    errorCode: string | null;
}

export default function EmailLogin(props: any) {
    const { login } = useAuth() as AuthContextType;
    const [state, setState] = useState<EmailLoginInputs>({
        email: null,
        password: null,
        errorCode: null
    });
    const navigate = useNavigate();

    async function Login() {
        try {
            let userCredential = await Firebase.signInWithEmailAndPassword(Firebase.auth, state.email as string, state.password as string);
            if (!userCredential.user.emailVerified) {
                sendEmailVerification(userCredential.user);
                navigate({
                    pathname: '/auth-email',
                    search: '?mode=verifyEmail&_action=waitForEmail',
                });
            } else {
                let firebaseToken = await userCredential.user.getIdToken();
                let dbUser = await getUser(firebaseToken);
                login({ firebaseUser: Firebase.auth.currentUser, dbUser });
            }
        } catch (err: any) {
            console.log("Failed login", err);
            let errorCode : string = err?.code || "Unknown error";
            setState({ ...state, errorCode });
            throw err;
        }
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { id, value } = e.target;
        setState({ ...state, [id]: value });
    }

    return (
        <div>

            <div className="mb-3">
                <label>Email address</label>
                <input
                    defaultValue={state.email as string}
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    id="email"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>

            <div className="mb-3">
                <label>Password</label>
                <input
                    defaultValue={state.password as string}
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    id="password"
                    onChange={onInputChange}
                    autoComplete="none"
                />
            </div>

            <div className="mb-3">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                    />
                    <label className="custom-control-label" htmlFor="customCheck1">
                        Remember me
                    </label>
                </div>
                <div>{state?.errorCode || ""}</div>
            </div>

            <div className="d-grid">
                <button className="btn btn-primary" onClick={Login}>
                    Submit
                </button>
            </div>
            <p className="dialogue-footer">
                <span className='text-left'>
                    <a href="/sign-up">Sign up</a>
                </span>
                <span className='text-right'>
                    Forgot <a href="/forgot-password">password</a>?
                </span>
            </p>
        </div>
    )

}
