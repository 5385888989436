import React, { Context, createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PERMISSIONS from "../permissions/Permissions";

export interface AuthContextType {
    user: WebUser;
    login: Function;
    logout: Function;
}

const AuthContext : Context<AuthContextType|null> = createContext<AuthContextType|null>(null);


const permissionsMap : { [role: string]: Array<PERMISSIONS>; } = {
    admin: [PERMISSIONS.CAN_VIEW_DASHBOARD, PERMISSIONS.CAN_VIEW_PROFILE],
    default: [PERMISSIONS.CAN_VIEW_PROFILE],
}

function getInitialState() {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : {}
}

function getRedirectPath(location: any, permissions: Array<PERMISSIONS>) {
    if (location.state?.path) {
        return location.state?.path;
    }
    if (permissions.includes(PERMISSIONS.CAN_VIEW_DASHBOARD)) {
        return "/dashboard";
    }
    return "/queue-checker";
}

interface WebUser  {
    dbUser: any;
    user: any;
    username: string;
    permissions: PERMISSIONS[];
}

export const AuthProvider = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(getInitialState());
    const login = (user: WebUser) => {
        let {
            dbUser
        } = user;
        let roles = dbUser.roles || [];
        let permissions = [...permissionsMap.default];
        roles.forEach((role : string )=> {
            permissions = permissions.concat(permissionsMap[role]);
        });
        let storeUser = { user, username: dbUser.identifier, permissions };
        localStorage.setItem('user', JSON.stringify(storeUser));
        setUser(storeUser);
        navigate(getRedirectPath(location, permissions), { replace: true });
    };
    const logout = () => {
        setUser({ user: {}, username: "", permissions: [] });
        localStorage.removeItem('user');
        navigate('/sign-in');
    };
    return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
    return useContext<AuthContextType|null>(AuthContext);
};