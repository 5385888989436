import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RecoverEmail from './auth-email-handlers/recover-email.component';
import ResetPassword from './auth-email-handlers/reset-password.component';
import VerifyEmail from './auth-email-handlers/verification-email.component';

let actionModes = ['resetPassword', 'recoverEmail', 'verifyEmail'];

export default function AuthEmail(props: any) {
    const [searchParams] = useSearchParams();
    const [actionMode, setActionMode] = useState<string>();
    const [oobCode, setOobCode] = useState<string>();

    useEffect(() => {
        setActionMode(searchParams.get('mode') as string);
        setOobCode(searchParams.get('oobCode') as string);
    }, [setActionMode, searchParams]);
    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div className='auth-main'>
                    {actionMode === 'resetPassword' &&
                        <ResetPassword
                            params={{ oobCode: oobCode as string }}
                        ></ResetPassword>
                    }
                    {actionMode === 'recoverEmail' &&
                        <RecoverEmail></RecoverEmail>
                    }
                    {actionMode === 'verifyEmail' &&
                        <VerifyEmail
                            params={{ oobCode: oobCode as string, _action: searchParams.get("_action") as string }}
                        ></VerifyEmail>
                    }
                    {(!actionMode || !actionModes.includes(actionMode as string)) &&
                        <div>
                            Go back to <a href="/sign-in">login</a> page?
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

