import React from "react"

export default function RecoverEmail(props : any) {
    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div className='auth-main'>
                    <div>
                        <h3>Email recovery not supported</h3>
                        <div>Go back to <a href="../sign-in">login</a> page</div>
                    </div>
                </div>
            </div>
        </div>
    )
}