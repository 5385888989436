import React from "react";
import { useEffect, useState } from "react";
import { getUser } from "../../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../../provider/AuthProvider";
import Firebase from "../../../provider/firebase";

interface Props {
    params: {
        oobCode: string
        _action: string
    }
}

export default function VerifyEmail({ params }: Props) {
    let { login } = useAuth() as AuthContextType;
    let [errorCode, setErrorCode] = useState();
    let [verificationSuccess, setVerificaitonSuccess] = useState(false);
    useEffect(() => {
        async function loginVerifiedUser() {
            if (Firebase?.auth?.currentUser?.emailVerified) {
                let firebaseToken = await Firebase.auth.currentUser.getIdToken();
                let dbUser = await getUser(firebaseToken);
                login({ firebaseUser: Firebase.auth.currentUser, dbUser });
            }
        }
        async function handleVerifyEmail() {
            try {
                await Firebase.applyActionCode(Firebase.auth, params.oobCode);
                console.log(Firebase.auth.currentUser);
                if (Firebase?.auth?.currentUser?.emailVerified) {
                    loginVerifiedUser();//Test
                } else {
                    setVerificaitonSuccess(true);
                }
            } catch (err: any) {
                console.error(err);
                setErrorCode(err?.code || "unknown_error");
            }
        }
        if (params.oobCode && !verificationSuccess) {
            handleVerifyEmail();
        } else {
            loginVerifiedUser();
        }
    })
    function getUserEmail() {
        if (Firebase?.auth?.currentUser?.email) {
            return Firebase.auth.currentUser.email;
        }
        return null;
    }
    return (
        <div>
            <h3>Verify email{params._action}</h3>
            {params._action === "waitForEmail" &&
                <div>
                    <div>
                        <span>We have sent you an email</span>
                        {true &&
                            <span>
                                <span>&nbsp;at&nbsp;</span><b>{getUserEmail()}</b>
                            </span>
                        }
                    </div>
                    <div>Please check your email and follow instructions.</div>

                </div>
            }
            {verificationSuccess &&
                <div>
                    <div>Verification successfull</div>
                    <div>Please <a href="/sign-in">login</a></div>
                </div>
                
            }
            {errorCode &&
                <div>
                    <div>Error: {errorCode}</div>
                </div>
            }
            <p className="dialogue-footer">
                {params._action === "waitForEmail" &&
                    <span className='text-left'>
                        <span>Resend email</span>?
                    </span>
                }
                {!verificationSuccess &&
                    <span className='text-right'>
                        Back to <a href="/sign-in">login</a>?
                    </span>
                }
            </p>
        </div>
    );
}