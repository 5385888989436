import React from 'react';

export default function ResetEmailSent(props: any) {

    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div className='auth-main'>
                    <h3>Password email sent</h3>
                    <p className="dialogue-footer">
                        <span className='text-left'>
                            Go back to <a href="../sign-in">login</a> page
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

