let CountryPhoneCodes = [
  {
    "name": "Afghanistan",
    "code": "93",
    "alpha_2": "AF",
    "alpha_3": "AFG"
  },
  {
    "name": "Albania",
    "code": "355",
    "alpha_2": "AL",
    "alpha_3": "ALB"
  },
  {
    "name": "Algeria",
    "code": "213",
    "alpha_2": "DZ",
    "alpha_3": "DZA"
  },
  {
    "name": "American Samoa",
    "code": "1-684",
    "alpha_2": "AS",
    "alpha_3": "ASM"
  },
  {
    "name": "Andorra",
    "code": "376",
    "alpha_2": "AD",
    "alpha_3": "AND"
  },
  {
    "name": "Angola",
    "code": "244",
    "alpha_2": "AO",
    "alpha_3": "AGO"
  },
  {
    "name": "Anguilla",
    "code": "1-264",
    "alpha_2": "AI",
    "alpha_3": "AIA"
  },
  {
    "name": "Antarctica",
    "code": "672",
    "alpha_2": "AQ",
    "alpha_3": "ATA"
  },
  {
    "name": "Antigua and Barbuda",
    "code": "1-268",
    "alpha_2": "AG",
    "alpha_3": "ATG"
  },
  {
    "name": "Argentina",
    "code": "54",
    "alpha_2": "AR",
    "alpha_3": "ARG"
  },
  {
    "name": "Armenia",
    "code": "374",
    "alpha_2": "AM",
    "alpha_3": "ARM"
  },
  {
    "name": "Aruba",
    "code": "297",
    "alpha_2": "AW",
    "alpha_3": "ABW"
  },
  {
    "name": "Australia",
    "code": "61",
    "alpha_2": "AU",
    "alpha_3": "AUS"
  },
  {
    "name": "Austria",
    "code": "43",
    "alpha_2": "AT",
    "alpha_3": "AUT"
  },
  {
    "name": "Azerbaijan",
    "code": "994",
    "alpha_2": "AZ",
    "alpha_3": "AZE"
  },
  {
    "name": "Bahamas",
    "code": "1-242",
    "alpha_2": "BS",
    "alpha_3": "BHS"
  },
  {
    "name": "Bahrain",
    "code": "973",
    "alpha_2": "BH",
    "alpha_3": "BHR"
  },
  {
    "name": "Bangladesh",
    "code": "880",
    "alpha_2": "BD",
    "alpha_3": "BGD"
  },
  {
    "name": "Barbados",
    "code": "1-246",
    "alpha_2": "BB",
    "alpha_3": "BRB"
  },
  {
    "name": "Belarus",
    "code": "375",
    "alpha_2": "BY",
    "alpha_3": "BLR"
  },
  {
    "name": "Belgium",
    "code": "32",
    "alpha_2": "BE",
    "alpha_3": "BEL"
  },
  {
    "name": "Belize",
    "code": "501",
    "alpha_2": "BZ",
    "alpha_3": "BLZ"
  },
  {
    "name": "Benin",
    "code": "229",
    "alpha_2": "BJ",
    "alpha_3": "BEN"
  },
  {
    "name": "Bermuda",
    "code": "1-441",
    "alpha_2": "BM",
    "alpha_3": "BMU"
  },
  {
    "name": "Bhutan",
    "code": "975",
    "alpha_2": "BT",
    "alpha_3": "BTN"
  },
  {
    "name": "Bolivia",
    "code": "591",
    "alpha_2": "BO",
    "alpha_3": "BOL"
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "387",
    "alpha_2": "BA",
    "alpha_3": "BIH"
  },
  {
    "name": "Botswana",
    "code": "267",
    "alpha_2": "BW",
    "alpha_3": "BWA"
  },
  {
    "name": "Brazil",
    "code": "55",
    "alpha_2": "BR",
    "alpha_3": "BRA"
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "246",
    "alpha_2": "IO",
    "alpha_3": "IOT"
  },
  {
    "name": "British Virgin Islands",
    "code": "1-284",
    "alpha_2": "VG",
    "alpha_3": "VGB"
  },
  {
    "name": "Brunei",
    "code": "673",
    "alpha_2": "BN",
    "alpha_3": "BRN"
  },
  {
    "name": "Bulgaria",
    "code": "359",
    "alpha_2": "BG",
    "alpha_3": "BGR"
  },
  {
    "name": "Burkina Faso",
    "code": "226",
    "alpha_2": "BF",
    "alpha_3": "BFA"
  },
  {
    "name": "Burundi",
    "code": "257",
    "alpha_2": "BI",
    "alpha_3": "BDI"
  },
  {
    "name": "Cambodia",
    "code": "855",
    "alpha_2": "KH",
    "alpha_3": "KHM"
  },
  {
    "name": "Cameroon",
    "code": "237",
    "alpha_2": "CM",
    "alpha_3": "CMR"
  },
  {
    "name": "Canada",
    "code": "1",
    "alpha_2": "CA",
    "alpha_3": "CAN"
  },
  {
    "name": "Cape Verde",
    "code": "238",
    "alpha_2": "CV",
    "alpha_3": "CPV"
  },
  {
    "name": "Cayman Islands",
    "code": "1-345",
    "alpha_2": "KY",
    "alpha_3": "CYM"
  },
  {
    "name": "Central African Republic",
    "code": "236",
    "alpha_2": "CF",
    "alpha_3": "CAF"
  },
  {
    "name": "Chad",
    "code": "235",
    "alpha_2": "TD",
    "alpha_3": "TCD"
  },
  {
    "name": "Chile",
    "code": "56",
    "alpha_2": "CL",
    "alpha_3": "CHL"
  },
  {
    "name": "China",
    "code": "86",
    "alpha_2": "CN",
    "alpha_3": "CHN"
  },
  {
    "name": "Christmas Island",
    "code": "61",
    "alpha_2": "CX",
    "alpha_3": "CXR"
  },
  {
    "name": "Cocos Islands",
    "code": "61",
    "alpha_2": "CC",
    "alpha_3": "CCK"
  },
  {
    "name": "Colombia",
    "code": "57",
    "alpha_2": "CO",
    "alpha_3": "COL"
  },
  {
    "name": "Comoros",
    "code": "269",
    "alpha_2": "KM",
    "alpha_3": "COM"
  },
  {
    "name": "Cook Islands",
    "code": "682",
    "alpha_2": "CK",
    "alpha_3": "COK"
  },
  {
    "name": "Costa Rica",
    "code": "506",
    "alpha_2": "CR",
    "alpha_3": "CRI"
  },
  {
    "name": "Croatia",
    "code": "385",
    "alpha_2": "HR",
    "alpha_3": "HRV"
  },
  {
    "name": "Cuba",
    "code": "53",
    "alpha_2": "CU",
    "alpha_3": "CUB"
  },
  {
    "name": "Curacao",
    "code": "599",
    "alpha_2": "CW",
    "alpha_3": "CUW"
  },
  {
    "name": "Cyprus",
    "code": "357",
    "alpha_2": "CY",
    "alpha_3": "CYP"
  },
  {
    "name": "Czech Republic",
    "code": "420",
    "alpha_2": "CZ",
    "alpha_3": "CZE"
  },
  {
    "name": "Democratic Republic of the Congo",
    "code": "243",
    "alpha_2": "CD",
    "alpha_3": "COD"
  },
  {
    "name": "Denmark",
    "code": "45",
    "alpha_2": "DK",
    "alpha_3": "DNK"
  },
  {
    "name": "Djibouti",
    "code": "253",
    "alpha_2": "DJ",
    "alpha_3": "DJI"
  },
  {
    "name": "Dominica",
    "code": "1-767",
    "alpha_2": "DM",
    "alpha_3": "DMA"
  },
  {
    "name": "Dominican Republic",
    "code": "1-809, 1-829, 1-849",
    "alpha_2": "DO",
    "alpha_3": "DOM"
  },
  {
    "name": "East Timor",
    "code": "670",
    "alpha_2": "TL",
    "alpha_3": "TLS"
  },
  {
    "name": "Ecuador",
    "code": "593",
    "alpha_2": "EC",
    "alpha_3": "ECU"
  },
  {
    "name": "Egypt",
    "code": "20",
    "alpha_2": "EG",
    "alpha_3": "EGY"
  },
  {
    "name": "El Salvador",
    "code": "503",
    "alpha_2": "SV",
    "alpha_3": "SLV"
  },
  {
    "name": "Equatorial Guinea",
    "code": "240",
    "alpha_2": "GQ",
    "alpha_3": "GNQ"
  },
  {
    "name": "Eritrea",
    "code": "291",
    "alpha_2": "ER",
    "alpha_3": "ERI"
  },
  {
    "name": "Estonia",
    "code": "372",
    "alpha_2": "EE",
    "alpha_3": "EST"
  },
  {
    "name": "Ethiopia",
    "code": "251",
    "alpha_2": "ET",
    "alpha_3": "ETH"
  },
  {
    "name": "Falkland Islands",
    "code": "500",
    "alpha_2": "FK",
    "alpha_3": "FLK"
  },
  {
    "name": "Faroe Islands",
    "code": "298",
    "alpha_2": "FO",
    "alpha_3": "FRO"
  },
  {
    "name": "Fiji",
    "code": "679",
    "alpha_2": "FJ",
    "alpha_3": "FJI"
  },
  {
    "name": "Finland",
    "code": "358",
    "alpha_2": "FI",
    "alpha_3": "FIN"
  },
  {
    "name": "France",
    "code": "33",
    "alpha_2": "FR",
    "alpha_3": "FRA"
  },
  {
    "name": "French Polynesia",
    "code": "689",
    "alpha_2": "PF",
    "alpha_3": "PYF"
  },
  {
    "name": "Gabon",
    "code": "241",
    "alpha_2": "GA",
    "alpha_3": "GAB"
  },
  {
    "name": "Gambia",
    "code": "220",
    "alpha_2": "GM",
    "alpha_3": "GMB"
  },
  {
    "name": "Georgia",
    "code": "995",
    "alpha_2": "GE",
    "alpha_3": "GEO"
  },
  {
    "name": "Germany",
    "code": "49",
    "alpha_2": "DE",
    "alpha_3": "DEU"
  },
  {
    "name": "Ghana",
    "code": "233",
    "alpha_2": "GH",
    "alpha_3": "GHA"
  },
  {
    "name": "Gibraltar",
    "code": "350",
    "alpha_2": "GI",
    "alpha_3": "GIB"
  },
  {
    "name": "Greece",
    "code": "30",
    "alpha_2": "GR",
    "alpha_3": "GRC"
  },
  {
    "name": "Greenland",
    "code": "299",
    "alpha_2": "GL",
    "alpha_3": "GRL"
  },
  {
    "name": "Grenada",
    "code": "1-473",
    "alpha_2": "GD",
    "alpha_3": "GRD"
  },
  {
    "name": "Guam",
    "code": "1-671",
    "alpha_2": "GU",
    "alpha_3": "GUM"
  },
  {
    "name": "Guatemala",
    "code": "502",
    "alpha_2": "GT",
    "alpha_3": "GTM"
  },
  {
    "name": "Guernsey",
    "code": "44-1481",
    "alpha_2": "GG",
    "alpha_3": "GGY"
  },
  {
    "name": "Guinea",
    "code": "224",
    "alpha_2": "GN",
    "alpha_3": "GIN"
  },
  {
    "name": "Guinea-Bissau",
    "code": "245",
    "alpha_2": "GW",
    "alpha_3": "GNB"
  },
  {
    "name": "Guyana",
    "code": "592",
    "alpha_2": "GY",
    "alpha_3": "GUY"
  },
  {
    "name": "Haiti",
    "code": "509",
    "alpha_2": "HT",
    "alpha_3": "HTI"
  },
  {
    "name": "Honduras",
    "code": "504",
    "alpha_2": "HN",
    "alpha_3": "HND"
  },
  {
    "name": "Hong Kong",
    "code": "852",
    "alpha_2": "HK",
    "alpha_3": "HKG"
  },
  {
    "name": "Hungary",
    "code": "36",
    "alpha_2": "HU",
    "alpha_3": "HUN"
  },
  {
    "name": "Iceland",
    "code": "354",
    "alpha_2": "IS",
    "alpha_3": "ISL"
  },
  {
    "name": "India",
    "code": "91",
    "alpha_2": "IN",
    "alpha_3": "IND"
  },
  {
    "name": "Indonesia",
    "code": "62",
    "alpha_2": "ID",
    "alpha_3": "IDN"
  },
  {
    "name": "Iran",
    "code": "98",
    "alpha_2": "IR",
    "alpha_3": "IRN"
  },
  {
    "name": "Iraq",
    "code": "964",
    "alpha_2": "IQ",
    "alpha_3": "IRQ"
  },
  {
    "name": "Ireland",
    "code": "353",
    "alpha_2": "IE",
    "alpha_3": "IRL"
  },
  {
    "name": "Isle of Man",
    "code": "44-1624",
    "alpha_2": "IM",
    "alpha_3": "IMN"
  },
  {
    "name": "Israel",
    "code": "972",
    "alpha_2": "IL",
    "alpha_3": "ISR"
  },
  {
    "name": "Italy",
    "code": "39",
    "alpha_2": "IT",
    "alpha_3": "ITA"
  },
  {
    "name": "Ivory Coast",
    "code": "225",
    "alpha_2": "CI",
    "alpha_3": "CIV"
  },
  {
    "name": "Jamaica",
    "code": "1-876",
    "alpha_2": "JM",
    "alpha_3": "JAM"
  },
  {
    "name": "Japan",
    "code": "81",
    "alpha_2": "JP",
    "alpha_3": "JPN"
  },
  {
    "name": "Jersey",
    "code": "44-1534",
    "alpha_2": "JE",
    "alpha_3": "JEY"
  },
  {
    "name": "Jordan",
    "code": "962",
    "alpha_2": "JO",
    "alpha_3": "JOR"
  },
  {
    "name": "Kazakhstan",
    "code": "7",
    "alpha_2": "KZ",
    "alpha_3": "KAZ"
  },
  {
    "name": "Kenya",
    "code": "254",
    "alpha_2": "KE",
    "alpha_3": "KEN"
  },
  {
    "name": "Kiribati",
    "code": "686",
    "alpha_2": "KI",
    "alpha_3": "KIR"
  },
  {
    "name": "Kosovo",
    "code": "383",
    "alpha_2": "XK",
    "alpha_3": "XKX"
  },
  {
    "name": "Kuwait",
    "code": "965",
    "alpha_2": "KW",
    "alpha_3": "KWT"
  },
  {
    "name": "Kyrgyzstan",
    "code": "996",
    "alpha_2": "KG",
    "alpha_3": "KGZ"
  },
  {
    "name": "Laos",
    "code": "856",
    "alpha_2": "LA",
    "alpha_3": "LAO"
  },
  {
    "name": "Latvia",
    "code": "371",
    "alpha_2": "LV",
    "alpha_3": "LVA"
  },
  {
    "name": "Lebanon",
    "code": "961",
    "alpha_2": "LB",
    "alpha_3": "LBN"
  },
  {
    "name": "Lesotho",
    "code": "266",
    "alpha_2": "LS",
    "alpha_3": "LSO"
  },
  {
    "name": "Liberia",
    "code": "231",
    "alpha_2": "LR",
    "alpha_3": "LBR"
  },
  {
    "name": "Libya",
    "code": "218",
    "alpha_2": "LY",
    "alpha_3": "LBY"
  },
  {
    "name": "Liechtenstein",
    "code": "423",
    "alpha_2": "LI",
    "alpha_3": "LIE"
  },
  {
    "name": "Lithuania",
    "code": "370",
    "alpha_2": "LT",
    "alpha_3": "LTU"
  },
  {
    "name": "Luxembourg",
    "code": "352",
    "alpha_2": "LU",
    "alpha_3": "LUX"
  },
  {
    "name": "Macau",
    "code": "853",
    "alpha_2": "MO",
    "alpha_3": "MAC"
  },
  {
    "name": "Macedonia",
    "code": "389",
    "alpha_2": "MK",
    "alpha_3": "MKD"
  },
  {
    "name": "Madagascar",
    "code": "261",
    "alpha_2": "MG",
    "alpha_3": "MDG"
  },
  {
    "name": "Malawi",
    "code": "265",
    "alpha_2": "MW",
    "alpha_3": "MWI"
  },
  {
    "name": "Malaysia",
    "code": "60",
    "alpha_2": "MY",
    "alpha_3": "MYS"
  },
  {
    "name": "Maldives",
    "code": "960",
    "alpha_2": "MV",
    "alpha_3": "MDV"
  },
  {
    "name": "Mali",
    "code": "223",
    "alpha_2": "ML",
    "alpha_3": "MLI"
  },
  {
    "name": "Malta",
    "code": "356",
    "alpha_2": "MT",
    "alpha_3": "MLT"
  },
  {
    "name": "Marshall Islands",
    "code": "692",
    "alpha_2": "MH",
    "alpha_3": "MHL"
  },
  {
    "name": "Mauritania",
    "code": "222",
    "alpha_2": "MR",
    "alpha_3": "MRT"
  },
  {
    "name": "Mauritius",
    "code": "230",
    "alpha_2": "MU",
    "alpha_3": "MUS"
  },
  {
    "name": "Mayotte",
    "code": "262",
    "alpha_2": "YT",
    "alpha_3": "MYT"
  },
  {
    "name": "Mexico",
    "code": "52",
    "alpha_2": "MX",
    "alpha_3": "MEX"
  },
  {
    "name": "Micronesia",
    "code": "691",
    "alpha_2": "FM",
    "alpha_3": "FSM"
  },
  {
    "name": "Moldova",
    "code": "373",
    "alpha_2": "MD",
    "alpha_3": "MDA"
  },
  {
    "name": "Monaco",
    "code": "377",
    "alpha_2": "MC",
    "alpha_3": "MCO"
  },
  {
    "name": "Mongolia",
    "code": "976",
    "alpha_2": "MN",
    "alpha_3": "MNG"
  },
  {
    "name": "Montenegro",
    "code": "382",
    "alpha_2": "ME",
    "alpha_3": "MNE"
  },
  {
    "name": "Montserrat",
    "code": "1-664",
    "alpha_2": "MS",
    "alpha_3": "MSR"
  },
  {
    "name": "Morocco",
    "code": "212",
    "alpha_2": "MA",
    "alpha_3": "MAR"
  },
  {
    "name": "Mozambique",
    "code": "258",
    "alpha_2": "MZ",
    "alpha_3": "MOZ"
  },
  {
    "name": "Myanmar",
    "code": "95",
    "alpha_2": "MM",
    "alpha_3": "MMR"
  },
  {
    "name": "Namibia",
    "code": "264",
    "alpha_2": "NA",
    "alpha_3": "NAM"
  },
  {
    "name": "Nauru",
    "code": "674",
    "alpha_2": "NR",
    "alpha_3": "NRU"
  },
  {
    "name": "Nepal",
    "code": "977",
    "alpha_2": "NP",
    "alpha_3": "NPL"
  },
  {
    "name": "Netherlands",
    "code": "31",
    "alpha_2": "NL",
    "alpha_3": "NLD"
  },
  {
    "name": "Netherlands Antilles",
    "code": "599",
    "alpha_2": "AN",
    "alpha_3": "ANT"
  },
  {
    "name": "New Caledonia",
    "code": "687",
    "alpha_2": "NC",
    "alpha_3": "NCL"
  },
  {
    "name": "New Zealand",
    "code": "64",
    "alpha_2": "NZ",
    "alpha_3": "NZL"
  },
  {
    "name": "Nicaragua",
    "code": "505",
    "alpha_2": "NI",
    "alpha_3": "NIC"
  },
  {
    "name": "Niger",
    "code": "227",
    "alpha_2": "NE",
    "alpha_3": "NER"
  },
  {
    "name": "Nigeria",
    "code": "234",
    "alpha_2": "NG",
    "alpha_3": "NGA"
  },
  {
    "name": "Niue",
    "code": "683",
    "alpha_2": "NU",
    "alpha_3": "NIU"
  },
  {
    "name": "North Korea",
    "code": "850",
    "alpha_2": "KP",
    "alpha_3": "PRK"
  },
  {
    "name": "Northern Mariana Islands",
    "code": "1-670",
    "alpha_2": "MP",
    "alpha_3": "MNP"
  },
  {
    "name": "Norway",
    "code": "47",
    "alpha_2": "NO",
    "alpha_3": "NOR"
  },
  {
    "name": "Oman",
    "code": "968",
    "alpha_2": "OM",
    "alpha_3": "OMN"
  },
  {
    "name": "Pakistan",
    "code": "92",
    "alpha_2": "PK",
    "alpha_3": "PAK"
  },
  {
    "name": "Palau",
    "code": "680",
    "alpha_2": "PW",
    "alpha_3": "PLW"
  },
  {
    "name": "Palestine",
    "code": "970",
    "alpha_2": "PS",
    "alpha_3": "PSE"
  },
  {
    "name": "Panama",
    "code": "507",
    "alpha_2": "PA",
    "alpha_3": "PAN"
  },
  {
    "name": "Papua New Guinea",
    "code": "675",
    "alpha_2": "PG",
    "alpha_3": "PNG"
  },
  {
    "name": "Paraguay",
    "code": "595",
    "alpha_2": "PY",
    "alpha_3": "PRY"
  },
  {
    "name": "Peru",
    "code": "51",
    "alpha_2": "PE",
    "alpha_3": "PER"
  },
  {
    "name": "Philippines",
    "code": "63",
    "alpha_2": "PH",
    "alpha_3": "PHL"
  },
  {
    "name": "Pitcairn",
    "code": "64",
    "alpha_2": "PN",
    "alpha_3": "PCN"
  },
  {
    "name": "Poland",
    "code": "48",
    "alpha_2": "PL",
    "alpha_3": "POL"
  },
  {
    "name": "Portugal",
    "code": "351",
    "alpha_2": "PT",
    "alpha_3": "PRT"
  },
  {
    "name": "Puerto Rico",
    "code": "1-787, 1-939",
    "alpha_2": "PR",
    "alpha_3": "PRI"
  },
  {
    "name": "Qatar",
    "code": "974",
    "alpha_2": "QA",
    "alpha_3": "QAT"
  },
  {
    "name": "Republic of the Congo",
    "code": "242",
    "alpha_2": "CG",
    "alpha_3": "COG"
  },
  {
    "name": "Reunion",
    "code": "262",
    "alpha_2": "RE",
    "alpha_3": "REU"
  },
  {
    "name": "Romania",
    "code": "40",
    "alpha_2": "RO",
    "alpha_3": "ROU"
  },
  {
    "name": "Russia",
    "code": "7",
    "alpha_2": "RU",
    "alpha_3": "RUS"
  },
  {
    "name": "Rwanda",
    "code": "250",
    "alpha_2": "RW",
    "alpha_3": "RWA"
  },
  {
    "name": "Saint Barthelemy",
    "code": "590",
    "alpha_2": "BL",
    "alpha_3": "BLM"
  },
  {
    "name": "Saint Helena",
    "code": "290",
    "alpha_2": "SH",
    "alpha_3": "SHN"
  },
  {
    "name": "Saint Kitts and Nevis",
    "code": "1-869",
    "alpha_2": "KN",
    "alpha_3": "KNA"
  },
  {
    "name": "Saint Lucia",
    "code": "1-758",
    "alpha_2": "LC",
    "alpha_3": "LCA"
  },
  {
    "name": "Saint Martin",
    "code": "590",
    "alpha_2": "MF",
    "alpha_3": "MAF"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "code": "508",
    "alpha_2": "PM",
    "alpha_3": "SPM"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "1-784",
    "alpha_2": "VC",
    "alpha_3": "VCT"
  },
  {
    "name": "Samoa",
    "code": "685",
    "alpha_2": "WS",
    "alpha_3": "WSM"
  },
  {
    "name": "San Marino",
    "code": "378",
    "alpha_2": "SM",
    "alpha_3": "SMR"
  },
  {
    "name": "Sao Tome and Principe",
    "code": "239",
    "alpha_2": "ST",
    "alpha_3": "STP"
  },
  {
    "name": "Saudi Arabia",
    "code": "966",
    "alpha_2": "SA",
    "alpha_3": "SAU"
  },
  {
    "name": "Senegal",
    "code": "221",
    "alpha_2": "SN",
    "alpha_3": "SEN"
  },
  {
    "name": "Serbia",
    "code": "381",
    "alpha_2": "RS",
    "alpha_3": "SRB"
  },
  {
    "name": "Seychelles",
    "code": "248",
    "alpha_2": "SC",
    "alpha_3": "SYC"
  },
  {
    "name": "Sierra Leone",
    "code": "232",
    "alpha_2": "SL",
    "alpha_3": "SLE"
  },
  {
    "name": "Singapore",
    "code": "65",
    "alpha_2": "SG",
    "alpha_3": "SGP"
  },
  {
    "name": "Sint Maarten",
    "code": "1-721",
    "alpha_2": "SX",
    "alpha_3": "SXM"
  },
  {
    "name": "Slovakia",
    "code": "421",
    "alpha_2": "SK",
    "alpha_3": "SVK"
  },
  {
    "name": "Slovenia",
    "code": "386",
    "alpha_2": "SI",
    "alpha_3": "SVN"
  },
  {
    "name": "Solomon Islands",
    "code": "677",
    "alpha_2": "SB",
    "alpha_3": "SLB"
  },
  {
    "name": "Somalia",
    "code": "252",
    "alpha_2": "SO",
    "alpha_3": "SOM"
  },
  {
    "name": "South Africa",
    "code": "27",
    "alpha_2": "ZA",
    "alpha_3": "ZAF"
  },
  {
    "name": "South Korea",
    "code": "82",
    "alpha_2": "KR",
    "alpha_3": "KOR"
  },
  {
    "name": "South Sudan",
    "code": "211",
    "alpha_2": "SS",
    "alpha_3": "SSD"
  },
  {
    "name": "Spain",
    "code": "34",
    "alpha_2": "ES",
    "alpha_3": "ESP"
  },
  {
    "name": "Sri Lanka",
    "code": "94",
    "alpha_2": "LK",
    "alpha_3": "LKA"
  },
  {
    "name": "Sudan",
    "code": "249",
    "alpha_2": "SD",
    "alpha_3": "SDN"
  },
  {
    "name": "Suriname",
    "code": "597",
    "alpha_2": "SR",
    "alpha_3": "SUR"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "code": "47",
    "alpha_2": "SJ",
    "alpha_3": "SJM"
  },
  {
    "name": "Swaziland",
    "code": "268",
    "alpha_2": "SZ",
    "alpha_3": "SWZ"
  },
  {
    "name": "Sweden",
    "code": "46",
    "alpha_2": "SE",
    "alpha_3": "SWE"
  },
  {
    "name": "Switzerland",
    "code": "41",
    "alpha_2": "CH",
    "alpha_3": "CHE"
  },
  {
    "name": "Syria",
    "code": "963",
    "alpha_2": "SY",
    "alpha_3": "SYR"
  },
  {
    "name": "Taiwan",
    "code": "886",
    "alpha_2": "TW",
    "alpha_3": "TWN"
  },
  {
    "name": "Tajikistan",
    "code": "992",
    "alpha_2": "TJ",
    "alpha_3": "TJK"
  },
  {
    "name": "Tanzania",
    "code": "255",
    "alpha_2": "TZ",
    "alpha_3": "TZA"
  },
  {
    "name": "Thailand",
    "code": "66",
    "alpha_2": "TH",
    "alpha_3": "THA"
  },
  {
    "name": "Togo",
    "code": "228",
    "alpha_2": "TG",
    "alpha_3": "TGO"
  },
  {
    "name": "Tokelau",
    "code": "690",
    "alpha_2": "TK",
    "alpha_3": "TKL"
  },
  {
    "name": "Tonga",
    "code": "676",
    "alpha_2": "TO",
    "alpha_3": "TON"
  },
  {
    "name": "Trinidad and Tobago",
    "code": "1-868",
    "alpha_2": "TT",
    "alpha_3": "TTO"
  },
  {
    "name": "Tunisia",
    "code": "216",
    "alpha_2": "TN",
    "alpha_3": "TUN"
  },
  {
    "name": "Turkey",
    "code": "90",
    "alpha_2": "TR",
    "alpha_3": "TUR"
  },
  {
    "name": "Turkmenistan",
    "code": "993",
    "alpha_2": "TM",
    "alpha_3": "TKM"
  },
  {
    "name": "Turks and Caicos Islands",
    "code": "1-649",
    "alpha_2": "TC",
    "alpha_3": "TCA"
  },
  {
    "name": "Tuvalu",
    "code": "688",
    "alpha_2": "TV",
    "alpha_3": "TUV"
  },
  {
    "name": "U.S. Virgin Islands",
    "code": "1-340",
    "alpha_2": "VI",
    "alpha_3": "VIR"
  },
  {
    "name": "Uganda",
    "code": "256",
    "alpha_2": "UG",
    "alpha_3": "UGA"
  },
  {
    "name": "Ukraine",
    "code": "380",
    "alpha_2": "UA",
    "alpha_3": "UKR"
  },
  {
    "name": "United Arab Emirates",
    "code": "971",
    "alpha_2": "AE",
    "alpha_3": "ARE"
  },
  {
    "name": "United Kingdom",
    "code": "44",
    "alpha_2": "GB",
    "alpha_3": "GBR"
  },
  {
    "name": "United States",
    "code": "1",
    "alpha_2": "US",
    "alpha_3": "USA"
  },
  {
    "name": "Uruguay",
    "code": "598",
    "alpha_2": "UY",
    "alpha_3": "URY"
  },
  {
    "name": "Uzbekistan",
    "code": "998",
    "alpha_2": "UZ",
    "alpha_3": "UZB"
  },
  {
    "name": "Vanuatu",
    "code": "678",
    "alpha_2": "VU",
    "alpha_3": "VUT"
  },
  {
    "name": "Vatican",
    "code": "379",
    "alpha_2": "VA",
    "alpha_3": "VAT"
  },
  {
    "name": "Venezuela",
    "code": "58",
    "alpha_2": "VE",
    "alpha_3": "VEN"
  },
  {
    "name": "Vietnam",
    "code": "84",
    "alpha_2": "VN",
    "alpha_3": "VNM"
  },
  {
    "name": "Wallis and Futuna",
    "code": "681",
    "alpha_2": "WF",
    "alpha_3": "WLF"
  },
  {
    "name": "Western Sahara",
    "code": "212",
    "alpha_2": "EH",
    "alpha_3": "ESH"
  },
  {
    "name": "Yemen",
    "code": "967",
    "alpha_2": "YE",
    "alpha_3": "YEM"
  },
  {
    "name": "Zambia",
    "code": "260",
    "alpha_2": "ZM",
    "alpha_3": "ZMB"
  },
  {
    "name": "Zimbabwe",
    "code": "263",
    "alpha_2": "ZW",
    "alpha_3": "ZWE"
  }
];
export default CountryPhoneCodes;