import React, { useEffect } from 'react'
import { AuthContextType, useAuth } from '../../provider/AuthProvider';
import Firebase from "../../provider/firebase";

export default function SignOut(props: any) {
    let { logout } = useAuth() as AuthContextType;
    async function doSignOut() {
        await Firebase.signOut(Firebase.auth);
        logout();
    }

    useEffect(() => {
        doSignOut();
    });

    return (
        <div className="narrow-page-wrapper">
            <div className="narrow-page-inner">
                <div>
                    You have been logged out
                </div>
            </div>
        </div>
    )

}
