import React, { useEffect, useState } from "react";
import ComboBoxComponent from "../common/combo-box.component";
import { getAirports } from "../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";

interface AirportSelectorProps {
    country: string;
    onSelectionUpdate: Function;
}

function AirportSelector({ country, onSelectionUpdate }: AirportSelectorProps) {
    let [ airports, setAirports ] = useState<any[]>([]);
    let [ currentCountry, setCurrentCountry ] = useState(country);
    let { user } = useAuth() as AuthContextType;
    useEffect(() => {
        async function loadData() {
            if (!country || (country === currentCountry && airports.length)) {
                return;
            }
            setCurrentCountry(country);
            let data = await getAirports(user.user.dbUser.appToken, country);
            data = data.filter((d: any) => {
                return !(!d.iata_code)
            });
            setAirports(data.map((data: any) => {
                return {id: data.iata_code, value: data.name}
            }));
        }
        loadData();
    }, [ airports, country, user, currentCountry ]);
    function onAirportSelected(airport: string) {
        onSelectionUpdate(airport);
    }
    return (
        <ComboBoxComponent
            data={airports}
            onSelectionUpdate={onAirportSelected}
            listLength={10}
            defaultMessage="Select airport"
        />
    );
}

export default AirportSelector;