import React from "react";
import styled from "styled-components";

const PrivacyPolicyStyles = styled.div`
    text-align:left;
    .privacy-policy-para {
        padding: 0.5rem 0 0 0;
    }
    .privacy-policy-heading {
        padding: 1rem 0 0 0;
        font-weight:bold
    }
`;
export default function PrivacyPolicy() {
    return (
        <div className="page-wrapper">
            <div className="page-inner">
                <div>
                    <h3>Privacy Policy</h3>
                    <PrivacyPolicyStyles>
                        <div className="privacy-policy-para">trypp.in Private Limited ("us", "we", or "our") operates the https://app.trypp.in website (the "Service").</div>
                        <div className="privacy-policy-para">This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.</div>
                        <div className="privacy-policy-para">We will not use or share your information with anyone except as described in this Privacy Policy.</div>
                        <div className="privacy-policy-para">We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at https://app.trypp.in</div>
                        <div className="privacy-policy-para">Information Collection And Use</div>
                        <div className="privacy-policy-para">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, other information ("Personal Information").</div>
                        <div className="privacy-policy-heading">Log Data</div>
                        <div className="privacy-policy-para">We collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</div>
                        <div className="privacy-policy-para">In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase our Service's functionality. These third party service providers have their own privacy policies addressing how they use such information.</div>
                        <div className="privacy-policy-heading">Cookies</div>
                        <div className="privacy-policy-para">Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</div>
                        <div className="privacy-policy-para">We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</div>
                        <div className="privacy-policy-heading">Service Providers</div>
                        <div className="privacy-policy-para">We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</div>
                        <div className="privacy-policy-para">These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</div>
                        <div className="privacy-policy-heading">Communications</div>
                        <div className="privacy-policy-para">We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</div>
                        <div className="privacy-policy-heading">Security</div>
                        <div className="privacy-policy-para">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</div>
                        <div className="privacy-policy-heading">International Transfer</div>
                        <div className="privacy-policy-para">Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</div>
                        <div className="privacy-policy-para">If you are located outside India and choose to provide information to us, please note that we transfer the information, including Personal Information, to India and process it there.</div>
                        <div className="privacy-policy-para">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</div>
                        <div className="privacy-policy-heading">Links To Other Sites</div>
                        <div className="privacy-policy-para">Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</div>
                        <div className="privacy-policy-para">We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</div>
                        <div className="privacy-policy-heading">Children's Privacy</div>
                        <div className="privacy-policy-para">Our Service does not address anyone under the age of 13 ("Children").</div>
                        <div className="privacy-policy-para">We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a children under age 13 without verification of parental consent, we take steps to remove that information from our servers.</div>
                        <div className="privacy-policy-heading">Changes To This Privacy Policy</div>
                        <div className="privacy-policy-para">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
                        <div className="privacy-policy-para">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                        <div className="privacy-policy-heading">Contact Us</div>
                        <div className="privacy-policy-para">If you have any questions about this Privacy Policy, please contact us at hello[at]trypp.in or via the contact form.
                        </div>
                    </PrivacyPolicyStyles>
                </div>
            </div>
        </div>
    );
}