import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutePath from "./routes/RoutePath";
import { AuthProvider } from "./provider/AuthProvider";
import { Navlinks } from './components/main/navlinks.component';
import Footer from './components/main/footer.component';


function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Navlinks></Navlinks>
          <RoutePath />
          <Footer></Footer>
        </div>
      </AuthProvider>
    </Router>
  )
}

export default App
