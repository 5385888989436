import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Envelope, Google, Phone } from 'react-bootstrap-icons';
import styled from 'styled-components';
import EmailLogin from './auth-methods/email-login.component';
import GoogleLogin from './auth-methods/google-login.component';
import PhoneLogin from './auth-methods/phone-login.component';
const LoginContainerStyles = styled.div`
    #auth-methods-tab {
        width: 100%
    }
`;
export default function Login() {
    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div className="auth-main">
                    <h3>Sign In</h3>
                    <LoginContainerStyles>
                        <Tabs
                            defaultActiveKey="email"
                            id="auth-methods-tab"
                            className="mb-3"
                        >
                            <Tab eventKey="email" title={<Envelope />}>
                                <EmailLogin></EmailLogin>
                            </Tab>
                            <Tab eventKey="google" title={<Google />}>
                                <GoogleLogin></GoogleLogin>
                            </Tab>
                            <Tab eventKey="phone" title={<Phone />}>
                                <PhoneLogin></PhoneLogin>
                            </Tab>
                        </Tabs>
                    </LoginContainerStyles>
                </div>
                <div className='auth-secondary'>
                    <a href="/terms" className='text-right'>
                        Terms
                    </a>
                    <a className='text-left' href="/privacy-policy">Privacy</a>
                </div>
            </div>
        </div>
    )

}
