import React, { useEffect, useState } from "react";
import { AuthMethod, createUser, getLocation, updateUserProfile } from "../../../api/api-wrapper";
import Firebase from "../../../provider/firebase";
import { AuthContextType, useAuth } from '../../../provider/AuthProvider';
import styled from "styled-components";
import CountryCodePicker from "../../common/country-code-picker.component";
import CountryPhoneCodes from "../../../utils/countryPhoneCodes";
import { ApplicationVerifier, ConfirmationResult } from "firebase/auth";

const PhoneLoginStyles = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    width: 100%;
    .phone-signin {
        width: 100%;
    }
    .phone-signin-heading {
        padding-bottom:1rem;
    }
    .phone-number-display {
        font-weight:bold;
    }
    #auth-methods-tab {
        width: 100%
    }
    .phone-input-container {
        display: grid;
    }
    .country-code-picker {
        grid-column: 1;
        grid-row: 1;
    }
    .phone-number-input {
        grid-column: 2;
        grid-row: 1;
        border-width: 0;
    }
`;

const PhoneLoginStates = {
    enterPhone: "enterPhone",
    enterOtp: "enterOtp",
    enterName: "enterName"
}

export default function PhoneLogin() {
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<ApplicationVerifier>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [pageState, setPageState] = useState(PhoneLoginStates.enterPhone);
    const [otp, setOtp] = useState<string>();
    const [name, setName] = useState<any>({});
    const [errorCode, setErrorCode] = useState();
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
    const [dbUser, setDbUser] = useState<any>();
    const [country, setCountry] = useState<any>({alpha_2:"CA"});
    const { login } = useAuth() as AuthContextType;
    useEffect(() => {
        let recaptchaVerifier = new Firebase.RecaptchaVerifier('recaptcha', {
            size: 'invisible'
        }, Firebase.auth);
        setRecaptchaVerifier(recaptchaVerifier);
        getLocation().then(data => {
            let countryPhoneCode = CountryPhoneCodes.find(c => c.alpha_2 === data.alpha_2);
            setCountry(countryPhoneCode)
        }).catch(console.error)
    }, [setRecaptchaVerifier]);
    function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        setPhoneNumber(value);
    }
    function getPhoneNumber() {
        return `+${country.code.replace("-", "")}${phoneNumber}`;
    }
    async function phoneLogin() {
        try {
            let number = getPhoneNumber();
            let confirmationResult = await Firebase.signInWithPhoneNumber(Firebase.auth, number, recaptchaVerifier as ApplicationVerifier);
            setConfirmationResult(confirmationResult);
            setPageState(PhoneLoginStates.enterOtp);
        } catch (err: any) {
            console.error(err);
            setErrorCode(err?.code || "unknwon_error");
        }
    }
    async function onOtpChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        setOtp(value);
    }
    async function checkOtp() {
        if (!confirmationResult || !otp) {
            setPageState(PhoneLoginStates.enterPhone);
            return;
        }
        try {
            let result = await confirmationResult.confirm(otp);
            const token = await result.user.getIdToken();
            let dbUser = await createUser(token, AuthMethod.phone);
            if (!dbUser.name) {
                setDbUser(dbUser);
                setPageState(PhoneLoginStates.enterName);
            } else {
                login({ firebaseUser: Firebase.auth.currentUser, dbUser });
            }
        } catch (err: any) {
            console.error(err);
            setErrorCode(err?.code || "unknwon_error");
        }
    }
    async function onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { id, value } = e.target;
        name[id] = value;
        setName(name);
    }
    async function changeName() {
        if (!dbUser) {
            setPageState(PhoneLoginStates.enterPhone);
            return;
        }
        try {
            await updateUserProfile(dbUser.appToken, name);
            login({ firebaseUser: Firebase.auth.currentUser, dbUser });
        } catch (err: any) {
            console.error(err);
            setErrorCode(err?.code || "unknwon_error");
        }
    }
    function handleCountrySelection(countryRow: any) {
        setCountry(countryRow);
    }
    return (
        <PhoneLoginStyles>
            <div className="phone-signin">

                {pageState === PhoneLoginStates.enterPhone &&
                    <div>
                        <div className="mb-3">
                            <div className="phone-input-container form-control">
                                <CountryCodePicker
                                    countries={CountryPhoneCodes}
                                    countryCode={country?.alpha_2}
                                    onSelectionUpdate={handleCountrySelection}
                                ></CountryCodePicker>
                                <input
                                    className="phone-number-input"
                                    placeholder="Enter phone number"
                                    id="phoneNumber"
                                    onChange={onPhoneChange}
                                    autoComplete="none"
                                />
                            </div>
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-primary" onClick={phoneLogin}>
                                Submit
                            </button>
                        </div>
                    </div>
                }
                {pageState === PhoneLoginStates.enterOtp &&
                    <div>
                        <div className="phone-signin-heading">
                            <span>Please enter otp sent to: </span>
                            <span className="phone-number-display">{getPhoneNumber()}</span>
                        </div>
                        <div>
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    placeholder="Enter otp"
                                    id="otp"
                                    onChange={onOtpChange}
                                    autoComplete="none"
                                />
                            </div>
                            <div className="d-grid">
                                <button className="btn btn-primary" onClick={checkOtp}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {pageState === PhoneLoginStates.enterName &&
                    <div>
                        <div className="mb-3">
                            <label>First name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="First name"
                                id="first_name"
                                onChange={onNameChange}
                                autoComplete="none"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Last name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Last name"
                                id="last_name"
                                onChange={onNameChange}
                                autoComplete="none"
                            />
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-primary" onClick={changeName}>
                                Submit
                            </button>
                        </div>
                    </div>
                }
                <div id="recaptcha"></div>
                <div>{errorCode}</div>
            </div>
        </PhoneLoginStyles>
    )
}