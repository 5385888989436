import React, { useState } from "react";
import { Check, X } from "react-bootstrap-icons";
import styled from "styled-components";

const EditBoxStyles = styled.label`
    background-color:white;
    border: 1px solid black;
    padding: 0.1rem;
    
    input {
        border: none;
    }
    span {
        padding: 0.2rem;
    }
    .tick {
        color: green;
    }
    .cross {
        color: red;
    }
`;

interface EditableFieldProps {
    dataKey: string;
    defaultValue?: string;
    saveValue?: Function;
}

export default function EditableField({dataKey, defaultValue, saveValue}: EditableFieldProps) {
    let [
        editMode, setEditMode
    ] = useState(false);
    let [
        value, setValue
    ] = useState(defaultValue);
    function toggleEditMode() {
        console.log(editMode);
        setEditMode(!editMode);
    }
    function save() {
        if (saveValue) {
            saveValue(dataKey, value)
        }
        setEditMode(false);
    }
    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        setValue(value);
    }
    return (
        <>
            {
                editMode &&
                <EditBoxStyles>
                    <input
                        onChange={handleOnChange}
                        value={value || ""}
                    />
                    <span className="tick" onClick={save}>
                        <Check />
                    </span>
                    <span className="cross" onClick={toggleEditMode}>
                        <X />
                    </span>
                </EditBoxStyles>

            }
            {
                !editMode &&
                    <span
                        onClick={toggleEditMode}
                    >
                        {value||"-"}
                    </span>
            }
        </>
    )
}