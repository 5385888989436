import React from 'react';
import { AuthContextType, useAuth } from '../../provider/AuthProvider';
import styled from 'styled-components';


const TableStyles = styled.div`
    padding: 1rem;

    table {
        width: 100%;
        border-spacing: 0;
        border: none;

        tr {
            width: 50%;
            :last-child {
                td {
                    border: none;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 1rem 3rem 1rem 4rem;
            border: none;
            text-align: left;

            :last-child {
                border-right: 0;
            }
        }
    }
    @media(max-width: 1250px) {
        table {
            td {
                padding: 0.5rem 1rem 1rem 2rem;
            }
        }
    }
    @media(max-width: 600px) {
        table {
            td {
                padding: 0.1rem 1rem 1rem 0.1rem;
            }
        }
    }
    @media(max-width: 400px) {
        table {
            td {
                padding: 0.1rem 0.5rem 0.1rem 0.1rem;
            }
        }
    }
`
const columnsToDisplay = [
    { name: "name", display: "Name"},
    { name: "email", display: "Email"},
    { name: "phone", display: "Phone"},
];
export default function Profile(props: any) {
    let { user } = useAuth() as AuthContextType;
    let rows : any = [];
    function getValue(columnName: string) {
        switch (columnName) {
            case "name" : {
                return user.user.dbUser[columnName];
            }
            case "email" : {
                return user.user.dbUser.user_type !== "phone" ? user.user.dbUser.identifier : user.user.firebaseUser.email;
            }
            case "phone" : {
                return user.user.dbUser.user_type === "phone" ? user.user.dbUser.identifier : user.user.firebaseUser.phoneNumber;
            }
            default: {
                return "";
            }
        }
    }
    columnsToDisplay.forEach(column => {
        rows.push(
            <tr key={column.name}>
                <td>
                    {column.display} :
                </td>
                <td>
                    {getValue(column.name)}
                </td>
            </tr>
        );
    })
    return (
        <div className="narrow-page-wrapper">
            <div className="narrow-page-inner">
                <div>
                    <h3>Profile</h3>
                    <TableStyles>
                        <table>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </TableStyles>
                </div>
            </div>
        </div>
    )

}
