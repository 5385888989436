import React from "react";
import styled from "styled-components";

const FooterStyles = styled.div`
    section {
        padding: 2.5rem 0 0 0;
    }

    section .section-title {
    }
    #footer {
        background: white;
    }
    .row {
        padding: 0.1rem;
        font-size: 0.8rem;
        text-align:left;
    }
    .footer-column {
        padding-bottom: 1rem;
    }
`;
export default function Footer() {
    return (
        <FooterStyles>
            <section id="footer">
                <div className="container">
                    <div className="row text-sm-left text-md-left">
                        <div className="col-xs-12 col-sm-6 col-md-6 footer-column">
                            <span className="brand-logo">
                                <img src="android-chrome-512x512.png" alt="✈"></img>
                            </span>
                            <span>app.trypp.in</span>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 footer-column">
                            <ul className="list-unstyled quick-links">
                                <li><a href="/privacy-policy">Privacy policy</a></li>
                                <li><a href="/terms">Terms</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">
                            <p>
                                © All rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </FooterStyles>
    );
}