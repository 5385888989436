import React, { useState } from "react";
import { Button, FormSelect } from "react-bootstrap";
import styled from 'styled-components'
import { getShortMonthName } from "../../utils/date-time-functions";

export const DateTimePickerStyles = styled.div`
    .date-time-picker {
        display:grid;
    }
    .date-time-picker button {
        margin: 0rem 0.5rem 0rem 0.5rem;
        padding: 0.4rem rem 0.4rem 1rem;
    }
    .date-time-picker-date {
        grid-column: 1;
        grid-row: 1;
    }
    .date-time-picker-hour {
        grid-column: 2;
        grid-row: 1;
    }
    .date-time-picker-time-separater {
        grid-column: 3;
        grid-row: 1;
        padding: 0.3rem 0rem 0rem 0.4rem;
        margin: 0rem 0.4rem 0rem 0rem;
        text-align:center;
        width:1.5rem;
    }
    .date-time-picker-min {
        grid-column: 4;
        grid-row: 1;
    }
    .date-time-picker-ampm {
        grid-column: 5;
        grid-row: 1;
    }
    .date-time-picker-gap {
        width: 1.5rem;
        grid-column: 6;
        grid-row: 1;
    }
    .date-time-picker-go {
        grid-column: 7;
        grid-row: 1;
    }

    @media(max-width: 600px) {
        .date-time-picker select {
            padding-left: 0.1rem;
            padding-right: 2rem;
        }
        .date-time-picker button {
            margin: 0;
        }
        .date-time-picker-date {
            grid-column: span 6;
            grid-row: 1;
        }
        .date-time-picker-hour {
            grid-column: 1;
            grid-row: 2;
        }
        .date-time-picker-time-separater {
            grid-column: 2;
            grid-row: 2;
            width:0.6rem;
        }
        .date-time-picker-min {
            grid-column: 3;
            grid-row: 2;
        }
        .date-time-picker-ampm {
            grid-column: 4;
            grid-row: 2;
        }
        .date-time-picker-gap {
            width: 0rem;
            grid-column: 5;
            grid-row: 2;
        }
        .date-time-picker-go {
            grid-column: 6;
            grid-row: 2;
        }
    }
`;

interface DateTimePickerProps {
    startTime: Date;
    endTime: Date;
    onPick: Function;
    maxDates: number;
    minInterval: number;
}

export default function DateTimePicker({startTime, endTime, onPick, maxDates=10, minInterval=5}: DateTimePickerProps) {
    let [ state, setState] = useState<any>({
        epoch: getDefaultValue("epoch"),
        hour: getDefaultValue("hour"),
        min: getDefaultValue("min"),
        ampm: getDefaultValue("ampm")
    });
    function getDates() {
        let dates = [];
        let ts = startTime.getTime();
        let currentDate = new Date(ts);
        currentDate.setMilliseconds(0);
        currentDate.setSeconds(0);
        currentDate.setMinutes(0);
        currentDate.setHours(0);
        while (currentDate.getTime() < endTime.getTime()) {
            dates.push({
                day: currentDate.getDate(),
                month: getShortMonthName(currentDate.getMonth()),
                year: currentDate.getFullYear(),
                epoch: currentDate.getTime()
            });
            currentDate.setDate(currentDate.getDate() + 1);
            if (dates.length >= maxDates) {
                break;
            }
        }
        return dates;
    }

    function getDateSelectors() {
        let dates = getDates();
        return dates.map(dt => {
            return (
                <option 
                    id={`${dt.epoch}`} 
                    key={dt.epoch}
                    value={dt.epoch}
                >
                    {dt.day}-{dt.month}-{dt.year}
                </option>
            );
        });
    }

    function getHourSelector() {
        let items = [];
        for (let index = 1; index <= 12; index++) {
            let displayValue = index;
            let displayValueStr = displayValue < 10 ? `0${displayValue}` : `${displayValue}`;
            items.push(
                <option 
                    id={`${index}`} 
                    key={index}
                    value={index}
                >
                    {displayValueStr}
                </option>
            );
        }
        return items;
    }

    function getAMPM() {
        let values = ["AM","PM"];
        return values.map(value => {
            return (
                <option 
                    id={value} 
                    key={value}
                    value={value}
                >
                    {value}
                </option>
            );
        });
    }

    function getMinuteSelector() {
        let items = [];
        for (let index = 0; index < 60; index = index + minInterval) {
            let displayValue = index;
            let displayValueStr = displayValue < 10 ? `0${displayValue}` : `${displayValue}`;
            items.push(
                <option 
                    id={`${index}`} 
                    key={index}
                    value={index}
                >
                    {displayValueStr}
                </option>
            );
        }
        return items;
    }
    function onValueChange(e: React.ChangeEvent<HTMLSelectElement>) {
        let { id, value} = e.target;
        state[id] = value;
        setState(state);
    }
    function getHour() {
        if (state.ampm === "AM" && +state.hour === 12) {
            return 0;
        }
        return (+state.hour + +(state.ampm === "PM" ? 12 : 0));
    }
    function onSubmit(){
        let dt = new Date(+state.epoch);
        let time = new Date(
            dt.getFullYear(),
            dt.getMonth(),
            dt.getDate(),
            getHour(),
            state.min
        );
        if (onPick) {
            onPick(time);
        }
    }
    function getDefaultValue(type: string): string {
        switch(type) {
            case ("epoch"): {
                return `${(startTime.getTime())}`;
            }
            case("hour"): {
                return `${(startTime.getHours()%12)}`;
            }
            case("min"): {
                return`${Math.round(startTime.getMinutes()/5)*5}`;
            }
            case("ampm"): {
                return `${(startTime.getHours() < 12 ? "AM" : "PM")}`;
            }
            default:
                return "";
        }
    }
    return (
        <DateTimePickerStyles>
            <div className="date-time-picker">
                <FormSelect 
                    id="epoch" 
                    className="date-time-picker-date" 
                    onChange={onValueChange}
                    defaultValue={getDefaultValue("epoch")}
                >
                    {getDateSelectors()}
                </FormSelect>
                <FormSelect 
                    id="hour" 
                    className="date-time-picker-hour" 
                    onChange={onValueChange}
                    defaultValue={getDefaultValue("hour")}
                >
                    {getHourSelector()}
                </FormSelect>
                <span className="date-time-picker-time-separater">:</span>
                <FormSelect 
                    id="min" 
                    className="date-time-picker-min" 
                    onChange={onValueChange}
                    defaultValue={getDefaultValue("min")}>
                        {getMinuteSelector()}
                </FormSelect>
                <FormSelect 
                    id="ampm" 
                    className="date-time-picker-ampm" 
                    onChange={onValueChange}
                    defaultValue={getDefaultValue("ampm")}
                >
                    {getAMPM()}
                </FormSelect>
                <span className="date-time-picker-gap"></span>
                <Button className="date-time-picker-go" onClick={onSubmit}>Go</Button>
            </div>
        </DateTimePickerStyles>
    );
}