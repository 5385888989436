import config from "../config";
import { TerminalAlias } from "./api-models";
export enum AuthMethod {
    email = "email",
    phone = "phone",
    google = "google"
}
export async function createUser(token: string, authMethod: AuthMethod) {
    let res = await fetch(
        `${config.api.endpoint}users?method=${authMethod}`,
        {
            method: 'POST',
            headers: {
                token
            }
        }
    )
    return (await res.json()).data;
}

export async function getUser(token: string) {
    let res = await fetch(
        `${config.api.endpoint}users`,
        {
            method: 'GET',
            headers: {
                token
            }
        }
    )
    return (await res.json()).data;
}

export async function getSchedules(token: string, airport: string) {
    let res = await fetch(
        `${config.api.endpoint}schedules?airport=${airport}`,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function getCountries(token: string) {
    let res = await fetch(
        `${config.api.endpoint}schedules/countries`,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function getAirports(token: string, country: string) {
    let res = await fetch(
        `${config.api.endpoint}schedules/airports?country=${country}`,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function getQueueWaitTime(token: string, airport: string, startTime: number, terminal: string|null) {
    let url = `${config.api.endpoint}schedules/queueWaitTime?airport=${airport}&startTime=${startTime}`;
    if (terminal) {
        url = `${url}&terminal=${terminal}`
    }
    let res = await fetch(
        url,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function updateUserProfile(token: string, args: any) {
    let res = await fetch(
        `${config.api.endpoint}users/profile`,
        {
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
                "content-type": "application/json"
            },
            body: JSON.stringify(args)
        }
    )
    return (await res.json()).data;
}

export async function getLocation() {
    let res = await fetch(
        `${config.api.endpoint}utils/location`,
        {
            method: 'GET'
        }
    )
    return (await res.json()).data;

}

export async function getTerminals(token: string, airport: string) {
    let res = await fetch(
        `${config.api.endpoint}schedules/airports/terminals?airport=${airport}`,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function getTerminalAlias(token: string, airport: string) {
    let res = await fetch(
        `${config.api.endpoint}schedules/airports/terminals/alias?airport=${airport}`,
        {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    )
    return (await res.json()).data;
}

export async function createTerminalAlias(token: string, req: TerminalAlias) {
    let res = await fetch(
        `${config.api.endpoint}schedules/airports/terminals/alias`,
        {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${token}`,
                "content-type": "application/json"
            },
            body: JSON.stringify(req)
        }
    )
    return (await res.json()).data;
}