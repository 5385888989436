import React from 'react';

const PageNotFound = () => {
    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <div>
                    <h2>404 Error</h2>
                    <p>Page Not Found</p>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;