// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  updateProfile,
  sendPasswordResetEmail,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  signInWithPhoneNumber,
  sendEmailVerification,
  verifyPasswordResetCode,
  checkActionCode,
  applyActionCode,
  confirmPasswordReset,
  RecaptchaVerifier
} from "firebase/auth";
import config from "../config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = config.firebase;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

//Google auth provider
const googleAuthProvider = new GoogleAuthProvider();
// googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');


//Exports
const Firebase = {
    app,
    analytics,
    auth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    sendPasswordResetEmail,
    signOut,
    googleAuthProvider,
    signInWithPopup,
    signInWithCredential,
    signInWithPhoneNumber,
    sendEmailVerification,
    verifyPasswordResetCode,
    checkActionCode,
    applyActionCode,
    confirmPasswordReset,
    RecaptchaVerifier
}

export default Firebase;