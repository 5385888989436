import React, { useEffect, useState } from "react";
import ComboBoxComponent from "../common/combo-box.component";
import { getCountries } from "../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

interface CountrySelectorProps {
    onSelectionUpdate: Function;
    defaultCountry: string;
}

function CountrySelector({onSelectionUpdate, defaultCountry}: CountrySelectorProps) {
    let [countries, setCountries] = useState([]);
    let { user } = useAuth() as AuthContextType;
    useEffect(() => {
        async function loadData() {
            if (countries.length) {
                return;
            }
            let data = await getCountries(user.user.dbUser.appToken);
            setCountries(data.map((d: any) => {
                return {id: d.alpha_2, value: `${getUnicodeFlagIcon(d.alpha_2)} ${d.country_name}`}
            }));
        }
        loadData();
    }, [countries, user])
    function onCountrySelected(country: string) {
        onSelectionUpdate(country)
    }
    return (
        <ComboBoxComponent
            data={countries}
            initialSelection={defaultCountry}
            onSelectionUpdate={onCountrySelected}
            listLength={10}
            defaultMessage="Select country"
        />
    );
}

export default CountrySelector;

