import { NumberLiteralType } from "typescript";

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export function getFullMonthName(monthIndex: number) {
    return monthNames[monthIndex];
}
export function getShortMonthName(monthIndex: number) {
    return monthNames[monthIndex].substring(0,3);
}

export function getDateSuffix(dateOfMonth: number) {
    dateOfMonth = +dateOfMonth;
    if (dateOfMonth > 3 && dateOfMonth < 21) {
        return "th";
    }
    let leastSignificatDigit = dateOfMonth % 10;
    switch (leastSignificatDigit) {
        case 1:
            return "st"
        case 2:
            return "nd"
        case 3:
            return "rd"
        default:
            return "th"
    }
}

function padZeros(number: number, length: number) {
    let numStr = `${number}`;
    if (numStr.length < length) {
        for (let index = 0; index <= length - numStr.length; index++) {
            numStr = `0${numStr}`;
        }
    }
    return numStr;
}

export function getDateParts(date: Date) {
    let dateParts: any = {
        date: date.getDate(),
        dateSuffix: getDateSuffix(date.getDate()),
        month: date.getMonth(),
        year: date.getFullYear(),
        hour: date.getHours() % 12,
        ampm: date.getHours() > 12 ? "PM" : "AM",
        min: date.getMinutes()
    }
    let paddedNumbers = ["min", "hour", "hour", "date", "month"];
    paddedNumbers.forEach(key => {
        dateParts[`${key}Str`] = padZeros(dateParts[key],2);
    });
    dateParts.shortMonthName = getShortMonthName(dateParts.month);
    dateParts.monthName = getFullMonthName(dateParts.month);
    return dateParts;
}