import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import styled from 'styled-components'


export const DropdownStyles = styled.div`
    .dropdown-menu {
        transform: translate(0px, 37px) !important;
    }
    .dropdown-combo-box-button,
    .dropdown-combo-box-input {
        min-width:14rem;
    }
`;

interface ComboBoxComponentProps {
    data: any[];
    initialSelection?: string;
    onSelectionUpdate: Function;
    listLength?: number;
    defaultMessage?: string;
}

function ComboBoxComponent({
    data,
    initialSelection,
    onSelectionUpdate,
    listLength=5,
    defaultMessage="No item selected"
}: ComboBoxComponentProps) {
    let [filteredData, setFilteredData] = useState<Array<any>>([]);
    let [selection, setSelection] = useState<string>(initialSelection as string);
    let [inputVisible, setInputVisible] = useState(false);
    let [dropdownVisible, setDropdownVisible] = useState(false);
    useEffect(() => {
        setFilteredData(data);
        if (initialSelection) {
            setSelection(initialSelection);
        }
    }, [ inputVisible, data, initialSelection ]);
    function selectionSetter(selection: string) {
        setSelection(selection);
        if (onSelectionUpdate) {
            onSelectionUpdate(selection);
        }
    }
    function handleSelectEvent(key: string | null, e: React.SyntheticEvent<unknown>) {
        let { id } = e.target as any;
        selectionSetter(id);
        setInputVisible(false);
        setDropdownVisible(false);
        setFilteredData(data);
    }
    function getDropdownItems() {
        if (!filteredData) {
            return [];
        }
        let items : Array<any> = [];
        filteredData.forEach(d => {
            items.push(
                <Dropdown.Item 
                    key={d.id}
                    id={d.id}
                >
                    {d.value}
                </Dropdown.Item>
            );
        });
        items = items.slice(0, listLength);
        return items;
    }
    function getSelectedItemName() {
        let currentData = data || [];
        let row = currentData.find(d => d.id === selection);
        if (row) {
            return row.value;
        }
        return defaultMessage;
    }
    function showInputBox(){
        setInputVisible(true);
        setDropdownVisible(true);
    }
    function showDropdown(){
        setDropdownVisible(true);
        setInputVisible(true);
    }
    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        value = value || "";
        let newFilteredData = data.filter(d => d.value.toLowerCase().includes(value.toLowerCase()));
        setFilteredData(newFilteredData);
    }
    function handleOnToggle() {
        setInputVisible(!dropdownVisible);
        setDropdownVisible(!dropdownVisible);
    }
    return (
        <DropdownStyles>
            <Dropdown bg="light" as={ButtonGroup} id="combo-box-control"
                autoClose={'outside'}
                show={dropdownVisible}
                onSelect={handleSelectEvent}
                onToggle={handleOnToggle}
                >
                {
                    !inputVisible && 
                    <Button 
                        onClick={showInputBox} 
                        className="dropdown-combo-box-button">
                            {getSelectedItemName()}
                    </Button>
                }
                {
                    inputVisible && 
                    <input 
                        autoFocus={true} 
                        onChange={onInputChange} 
                        className="dropdown-combo-box-input"
                    >
                    </input>
                }

                <Dropdown.Toggle split onClick={showDropdown}/>

                <Dropdown.Menu>
                    {getDropdownItems()}
                </Dropdown.Menu>
            </Dropdown>
        </DropdownStyles>
    );
}

export default ComboBoxComponent;