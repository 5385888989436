import React from "react"
import { useEffect, useState } from "react"
import Firebase from "../../../provider/firebase";

enum PageStates {
    resetCodeVerified= "resetCodeVerified",
    resetCodeFailed= "resetCodeFailed",
    passwordResetSuccess= "passwordResetSuccess",
    passwordResetFailed= "passwordResetFailed"
}


interface Props {
    params: {
        oobCode: string
    }
}

export default function ResetPassword({ params }: Props) {
    let [pageState, setPageState] = useState<PageStates>();
    let [accountEmail, setAccountEmail] = useState<string>();
    let [errorCode, setErrorCode] = useState<string>();
    let [password, setPassword] = useState<string>();
    useEffect(() => {
        async function handleResetPassword() {
            try {
                let email = await Firebase.verifyPasswordResetCode(Firebase.auth, params.oobCode);
                setAccountEmail(email);
                setPageState(PageStates.resetCodeVerified);
            } catch (err: any) {
                console.error("handleResetPassword", err);
                setErrorCode(err?.code || "unknown_error");
                setPageState(PageStates.resetCodeFailed);
            }
        }
        handleResetPassword();
    }, [setAccountEmail, setPageState, params])
    async function resetPassword() {
        try {
            await Firebase.confirmPasswordReset(Firebase.auth, params.oobCode, password as string);
            setPageState(PageStates.passwordResetSuccess);
        } catch (err: any) {
            console.error(err);
            setErrorCode(err?.code || "unknown_error");
            setPageState(PageStates.passwordResetFailed);
        }
    }
    function onPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        setPassword(value);
    }
    return (

        <div>
            {pageState === PageStates.resetCodeVerified &&
                <div>
                    <h3>Set new password</h3>
                    <div className="mb-3">
                        <div>Set new password for <b>{accountEmail}</b></div>
                        <input 
                            className="form-control"
                            id="newPassword"
                            type="password"
                            onChange={onPasswordChange}>    
                        </input>
                    </div>
                    <div className="d-grid">
                        <button className="btn btn-primary" onClick={resetPassword}>
                            Reset password
                        </button>
                    </div>
                </div>
            }

            {pageState === PageStates.passwordResetFailed &&
                <div>
                    <h3>Failed to set password</h3>
                    <div>Please <a href="/forgot-password">retry</a></div>
                </div>
            }
            {pageState === PageStates.resetCodeFailed &&
                <div>
                    <h3>Password reset failed</h3>
                    <div>Invalid code, please <a href="/forgot-password">retry</a></div>
                </div>
            }
            {pageState === PageStates.passwordResetSuccess &&
                <div>
                    <h3>Password set successfully</h3>
                    <div>Please <a href="sign-in">login</a></div>
                </div>
            }
            {errorCode &&
                <div>
                    <div>Error: {errorCode}</div>
                </div>
            }
        </div>
    )
}