import React, { useEffect, useState } from "react";
import { createTerminalAlias, getTerminals } from "../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";
import EditableField from "../common/editable-field.component";
import Table, { TableStyles } from "../common/table.component";



interface SchedulesProps {
    airport: string;
}

function TerminalList({airport}: SchedulesProps) {
    let [ terminals, setTerminals ] = useState([]);
    let [ currentAirport, setCurrentAirport ] = useState<string>();
    let { user } = useAuth() as AuthContextType;

    async function createAlias(terminal: string, alias: string) {
        let req = {
            airport,
            terminal,
            alias
        }
        createTerminalAlias(user.user.dbUser.appToken, req);
    }

    const columns = [
        {
            Header: 'Terminal',
            accessor: 'dep_terminal'
        }, {
            Header: 'Alias',
            accessor: 'alias',
            customDisplay: ((value: string, data: any) => {
                return <EditableField dataKey={data.dep_terminal} defaultValue={value} saveValue={createAlias}/>
            })
        }, {
            Header: 'Flights',
            accessor: 'flights'
        }
    ];

    useEffect(()=> {
        async function loadData() {
            if (!airport || airport === currentAirport) {
                return;
            }
            setCurrentAirport(airport);
            let data = await getTerminals(user.user.dbUser.appToken, airport);
            setTerminals(data);
        }
        loadData();
    }, [ user, terminals, airport, currentAirport ]);

    return(
        <TableStyles>
            <Table columns={columns} data={terminals} defaultPageSize={10} initialPage={0}/>
        </TableStyles>
    );
}

export default TerminalList;